"use client";
import { Card } from "@mantine/core";
import styles from "./index.module.css";
import { SecondaryOutlineButton } from "../resuables/Button";
import Image from "next/image";
import TooltipKT from "../resuables/Tooltip";

export default function CreatorCard() {
    return (
        <>
            <h3 className={styles.sectionHeading}>Join us as partners</h3>
            <section className={styles.partnercardgroup}>
                <Card className={styles.partnercard}>
                    <Card.Section>
                        <TooltipKT label="Photo credit:Annie Spratt">
                            <Image
                                src="https://kodama-web-asset.s3.eu-west-2.amazonaws.com/general/creatorcard.jpg"
                                alt="Kodama Creator programme"
                                width={150}
                                height={280}
                                unoptimized
                            />
                        </TooltipKT>
                    </Card.Section>
                    <section className={styles.cardinfo}>
                        <h4 className={styles.cardtitle}>Creators</h4>
                        <p className={styles.cardcontent}>
                            Join our Creator Programme and share you crafted
                            contents with our audience.
                        </p>
                        <SecondaryOutlineButton
                            onClick={() => (window.location.href = "/creator")}
                        >
                            Find out more
                        </SecondaryOutlineButton>
                    </section>
                </Card>
                <Card className={styles.partnercard}>
                    <Card.Section>
                        <TooltipKT label="Photo credit:Marten Bjork">
                            <Image
                                src="https://kodama-web-asset.s3.eu-west-2.amazonaws.com/general/suppliercard.jpg"
                                alt="Kodama supplier programme"
                                width={150}
                                height={280}
                                unoptimized
                            />
                        </TooltipKT>
                    </Card.Section>

                    <section className={styles.cardinfo}>
                        <h4 className={styles.cardtitle}>Suppliers</h4>
                        <p className={styles.cardcontent}>
                            Become a Kodama supplier and showcase your
                            responsible travle experiences.
                        </p>
                        <SecondaryOutlineButton
                            onClick={() => (window.location.href = "/supplier")}
                        >
                            Find out more
                        </SecondaryOutlineButton>
                    </section>
                </Card>
            </section>
        </>
    );
}
