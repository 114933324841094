"use client";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { experienceType } from "../../utils/experienceType";
import styles from "./search.module.css";

export default function ExperienceBarIndex() {
    const experiences = experienceType.flatMap((category) => category.types);

    return (
        <Carousel
            slideSize="12%"
            slideGap="sm"
            loop
            align="start"
            slidesToScroll={5}
            m="15px"
        >
            {experiences.map((type) => (
                <Carousel.Slide
                    key={type.title}
                    onClick={() =>
                        (window.location.href = `/activity/${encodeURIComponent(
                            type.title
                        )}`)
                    }
                >
                    <div className={styles.experiencecard}>
                        <figure className={styles.experienceicon}>
                            {" "}
                            {type.img}
                        </figure>

                        <p className={styles.label}>{type.title}</p>
                    </div>
                </Carousel.Slide>
            ))}
        </Carousel>
    );
}
