"use client";
import { featureCountry } from "../../utils/geographies";
import Image from "next/image";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import styles from "./index.module.css";
import { useRouter } from "next/navigation";

export default function FeaturedCountries() {
    const router = useRouter();

    return (
        <>
            <h3 id="1" className={styles.sectionHeading}>
                Featured Destinations
            </h3>

            <Carousel
                slideGap="sm"
                align="start"
                slideSize="12%"
                slidesToScroll={2}
                loop
                classNames={{ controls: styles.controls }}
            >
                {featureCountry.map((country, index) => (
                    <Carousel.Slide key={index}>
                        <div
                            onClick={() =>
                                router.push(
                                    `/country/${encodeURIComponent(
                                        country.countryName
                                    )}`
                                )
                            }
                            className={styles.featureCard}
                            key={country.countryName}
                        >
                            <Image
                                key={country.feature_img}
                                src={country.feature_img}
                                alt={country.countryName}
                                width={150}
                                height={200}
                                unoptimized
                                className={styles.featureImage}
                            />

                            <h4 className={styles.featureHeading}>
                                {country.countryName}
                            </h4>
                        </div>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </>
    );
}
