"use client";
import { Text } from "@mantine/core";
import SearchGroup from "./SearchGroup";
import styles from "./index.module.css";

export default function Hero() {
    return (
        <>
            <section className={styles.root}>
                <div className={styles.content}>
                    <h1 className={styles.title}>
                        Make{" "}
                        <Text component="span" inherit c="#ffe845" ff="heading">
                            Responsible Travel
                        </Text>{" "}
                        the norm
                    </h1>

                    <h2 className={styles.description}>
                        We are a travel company with a{" "}
                        <Text
                            component="span"
                            inherit
                            className={styles.spantext}
                            onClick={() =>
                                (window.location.href = "/conservation")
                            }
                        >
                            conservation mission
                        </Text>
                        . We exist to make it easy to choose travle experiences
                        that are kind to wildlife and the environment.{" "}
                    </h2>

                    <SearchGroup />
                </div>
            </section>
        </>
    );
}
