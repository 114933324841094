import { Tooltip } from "@mantine/core";
interface TooltipKTProps {
    label: string;
}

const TooltipKT = ({
    children,
    ...props
}: { children: any } & TooltipKTProps) => {
    return (
        <Tooltip position="bottom" color="gray" offset={1} {...props}>
            {children}
        </Tooltip>
    );
};

export default TooltipKT;
